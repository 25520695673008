import * as React from "react"

function Youtube() {
  return (
    <a href="https://www.youtube.com/channel/UCKy3RC5iXAHwnNTEQg1XaiA">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.7111 8.70005C28.7111 8.70005 28.4279 6.70063 27.5557 5.82271C26.4512 4.66724 25.2164 4.66157 24.65 4.5936C20.5945 4.29907 14.5057 4.29907 14.5057 4.29907H14.4943C14.4943 4.29907 8.40547 4.29907 4.35 4.5936C3.78359 4.66157 2.54883 4.66724 1.44434 5.82271C0.57207 6.70063 0.294531 8.70005 0.294531 8.70005C0.294531 8.70005 0 11.0506 0 13.3956V15.5932C0 17.9381 0.288867 20.2887 0.288867 20.2887C0.288867 20.2887 0.57207 22.2881 1.43867 23.1661C2.54316 24.3215 3.99316 24.2819 4.63887 24.4065C6.96113 24.6274 14.5 24.6954 14.5 24.6954C14.5 24.6954 20.5945 24.684 24.65 24.3952C25.2164 24.3272 26.4512 24.3215 27.5557 23.1661C28.4279 22.2881 28.7111 20.2887 28.7111 20.2887C28.7111 20.2887 29 17.9438 29 15.5932V13.3956C29 11.0506 28.7111 8.70005 28.7111 8.70005ZM11.5037 18.261V10.1104L19.3371 14.1999L11.5037 18.261Z"
          fill="white"
        />
      </svg>
    </a>
  )
}

export default Youtube
