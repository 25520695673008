import * as React from "react"

function Tiktok() {
  return (
    <a href="https://www.tiktok.com/@willsassmusic?lang=en">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6287 0H15.7413V19.7536C15.7413 22.1073 13.8616 24.0406 11.5223 24.0406C9.18309 24.0406 7.30335 22.1073 7.30335 19.7536C7.30335 17.4421 9.14132 15.5507 11.397 15.4667V10.5073C6.42613 10.5913 2.41602 14.6681 2.41602 19.7536C2.41602 24.8812 6.50968 29 11.5641 29C16.6185 29 20.7122 24.8391 20.7122 19.7536V9.62462C22.5501 10.9696 24.8058 11.7681 27.1868 11.8102V6.85073C23.5109 6.72464 20.6287 3.69855 20.6287 0Z"
          fill="white"
        />
      </svg>
    </a>
  )
}

export default Tiktok
