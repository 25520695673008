import * as React from "react"

function Twitter() {
  return (
    <a href="https://twitter.com/willsass">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_26_159)">
          <path
            d="M9.12311 26.2813C20.0638 26.2813 26.0496 17.2148 26.0496 9.35481C26.0496 9.09993 26.0439 8.83938 26.0326 8.5845C27.197 7.74241 28.2019 6.69937 29 5.50438C27.9155 5.98687 26.7642 6.30198 25.5851 6.43895C26.8265 5.69483 27.7561 4.52584 28.2014 3.1487C27.0335 3.84082 25.7563 4.32905 24.4246 4.59247C23.5273 3.63903 22.3409 3.00774 21.0488 2.79621C19.7567 2.58467 18.4309 2.80466 17.2764 3.42218C16.1219 4.03969 15.203 5.02033 14.6617 6.21248C14.1205 7.40463 13.987 8.74189 14.2819 10.0175C11.9172 9.89884 9.60376 9.28454 7.49164 8.21443C5.37953 7.14432 3.51588 5.6423 2.0215 3.80573C1.26198 5.11523 1.02957 6.6648 1.3715 8.1395C1.71342 9.61421 2.60403 10.9034 3.86232 11.745C2.91768 11.7151 1.99372 11.4607 1.1668 11.0031V11.0767C1.16595 12.4509 1.64103 13.783 2.51129 14.8466C3.38155 15.9101 4.59327 16.6395 5.94047 16.9107C5.0654 17.1501 4.14698 17.185 3.25627 17.0126C3.63642 18.1945 4.37607 19.2281 5.37197 19.9694C6.36788 20.7106 7.57035 21.1224 8.81158 21.1474C6.70435 22.8027 4.10129 23.7005 1.42168 23.6962C0.946472 23.6955 0.471732 23.6664 0 23.609C2.7222 25.3554 5.88885 26.283 9.12311 26.2813Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_26_159">
            <rect width="29" height="29" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  )
}

export default Twitter
