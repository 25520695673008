import React, { useState, useEffect } from "react"
import { Layout } from "../components/layout"
import Instagram from "../icons/instagram"
import Tiktok from "../icons/tiktok"
import Twitter from "../icons/twitter"
import Youtube from "../icons/youtube"
import VideoBackground from "../components/background"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

export default function IndexPage() {
  const [showTitle, setTitle] = useState(false)
  const [showDescription, setDescription] = useState(false)
  //   the useEffect will run on the first rendering of the App component
  //   after two seconds (about how long it takes for the data to load)
  //   the loaded state will become true

  useEffect(() => {
    let timer = setTimeout(() => setTitle(true), 100)
    let timer2 = setTimeout(() => setDescription(true), 600)

    return () => {
      clearTimeout(timer2)
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout>
      <VideoBackground />

      <div className={`title ${showTitle ? "show--title" : ""}`}>
        <StaticImage
          src="../../static/WILLSASS_TITLE_WHITE1.png"
          alt="will sass"
          layout="constrained"
          objectFit="cover"
          placeholder="blurred"
          width="1288"
        />
      </div>

      <div className={`title_will ${showTitle ? "show--will" : ""}`}>
        <StaticImage
          src="../../static/WILL_PNG_WHITE.png"
          alt="will"
          layout="constrained"
          objectFit="inherit"
          placeholder="blurred"
        />
      </div>

      <div className={`title_sass ${showTitle ? "show--sass" : ""}`}>
        <StaticImage
          src="../../static/SASS_PNG_WHITE.png"
          alt="sass"
          layout="constrained"
          objectFit="inherit"
          placeholder="blurred"
        />
      </div>

      <footer className="description-wrapper">
        <a
          className={`description ${
            showDescription ? "show--description" : ""
          }`}
          href="https://www.caa.com/entertainmenttalent/touring/artist/willsass"
        >
          CONTACT
        </a>
        <div className={`socials ${showDescription ? "show--social" : ""}`}>
          <Instagram />
          <Tiktok />
          <Twitter />
          <Youtube />
        </div>
      </footer>
    </Layout>
  )
}
