import React, { Component, useState, useEffect } from "react"

const VideoBackground = () => {
  const [isGreaterThan900px, setIsGreaterThan900px] = useState(false)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 900) {
        setIsGreaterThan900px(true)
      } else {
        setIsGreaterThan900px(false)
      }
    }

    handleResize()
  }, [])

  return (
    <>
      <div className="video-overlay"></div>
      {isGreaterThan900px ? (
        <video id="background-video" loop autoPlay muted>
          <source src="/WILLSASS_FLOWERBUNCH_AI.mp4" type="video/mp4" />
          <source src="/WILLSASS_FLOWERBUNCH_AI.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <></>
      )}
    </>
  )
}
export default VideoBackground
